import React from "react"
import { Component } from "react";

class SingleArticle extends Component{

    articleTitle = this.props.articleTitle;
    articleContent = this.props.articleContent;
    articleImg = this.props.articleImg;

    render(){
        return (
            <div>
                <div>
                    <h2 className="home_title text-primary-dark font-weight-800 letter-spacing-n2 mb-4 text-center">{this.articleTitle}</h2>
                </div>

                <div className="blog-article-img-wrapper">
                    <img className="blog-article-img" src={this.articleImg?.mediaItemUrl ?? ""} alt=""></img>
                </div>

                <div className="blog-article-text" dangerouslySetInnerHTML={{ __html: this.articleContent}}></div>
            </div>
        )
    }
}

export default SingleArticle
