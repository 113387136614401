import React from "react"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import { Container } from "react-bootstrap"
import Layout from "../components/layout";
import SingleArticle from "../components/SingleArticle";

const ArticlePage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "blog page"}}>

            <SEO title={"Article"} />
            <CovidMessage></CovidMessage>

            <Container className="mt-4 mb-4 blog-page">
                {console.log(data)}
                <SingleArticle articleTitle={data?.post?.title} articleContent={data?.post.content} articleImg={data?.post?.blogPostInfo?.img}></SingleArticle>
            </Container>

        </Layout>
    )
}

export const data = graphql`
    query ($id: String) {
        post: wpPost(id: {eq: $id}) {
            title
            content
            blogPostInfo {
                date
                img {
                    mediaItemUrl
                    altText
                }
            }
        }
    }
`

export default ArticlePage
